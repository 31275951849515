<template>
  <div>
    <v-overlay :value="loading" opacity="0" absolute>
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="!loading">
      <v-container>
        <app-link-back-to-deposits
          :text="$t('Back to Deposits')"
          :to="backRoute"
        />
        <h1>
          {{ $t('Edit DepositTitle draft', {title: depositType.title}) }}
        </h1>
      </v-container>

      <v-form ref="form" v-model="valid">
        <v-container class="deposit-container pb-0 pb-sm-3">
          <v-row class="mx-n3 mx-sm-0" no-gutters>
            <v-col>
              <v-card
                :ripple="false"
                class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
                flat
              >
                <form-deposit
                  v-if="deposit"
                  :deposit-type="depositType"
                  :disabled="saving || removing"
                  :server-feedback="serverFeedback"
                  :value="deposit"
                  @input="input"
                />

                <v-row>
                  <v-col class="d-flex align-start justify-end flex-wrap">
                    <v-btn
                      :disabled="saving || !allowEdit"
                      :loading="removing"
                      :ripple="false"
                      class="mb-3"
                      color="grey"
                      rounded
                      outlined
                      @click="trash"
                    >
                      {{ $t('Delete draft') }}
                    </v-btn>
                    <v-btn
                      :disabled="removing || !allowEdit"
                      :loading="saving"
                      :ripple="false"
                      class="ml-5"
                      color="primary"
                      elevation="0"
                      rounded
                      @click="submit"
                    >
                      {{ $t('Save changes') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import FormDeposit from '@/components/FormDeposit'
import error401handler from '@/mixins/error-401-handler'
import {sections} from '@/store/const-deposit-statuses'

export default {
  name: 'DepositEdit',
  components: {AppLinkBackToDeposits, FormDeposit},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
    depositId: {type: [Number, String], required: true}
  },
  data () {
    return {
      deposit: {},
      serverFeedback: {
        name: null,
        description: null,
        type: null,
        content: null,
        language: null,
        actors: null
      },
      valid: true,
      loading: false,
      saving: false,
      removing: false
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      profile: state => state.profile,
      depositTypes: state => state.config.deposit.types,

      currentPagePrv: state => state.deposits.pagination.currentPage,
      currentPageBiz: state => state.depositsBusiness.pagination.currentPage,
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    currentPage () {
      return this.isBusinessAccount ? this.currentPageBiz : this.currentPagePrv
    },
    ...mapGetters({
      getDepositPrvById: 'deposits/getById',
      getDepositBizById: 'depositsBusiness/getById',
      editablePrv: 'deposits/editable',
      editableBiz: 'depositsBusiness/editable',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    getDepositById () {
      return this.isBusinessAccount ? this.getDepositBizById : this.getDepositPrvById
    },
    editable () {
      return this.isBusinessAccount ? this.editableBiz : this.editablePrv
    },

    allowEdit () {
      return !this.isBusinessAccount ||
        (this.isBusinessAccount && this.profile.role === 'teammate')
    },
    depositSource () {
      return this.getDepositById(this.depositId)
    },
    depositType () {
      return this.deposit?.type
        ? this.depositTypes[this.deposit.type]
        : {}
    },
    contentType () {
      return this.depositType.content_type
    },
    backRoute () {
      let section = sections.find(s => {
        return s.backFromRoutes.includes(this.$route.name)
      })

      let route = {
        name: 'deposits',
        params: {
          projectId: 'all', // this.projectId,
          sectionId: section.id
        }
      }
      if (this.currentPage > 1) {
        route.query = {page: this.currentPage}
      }
      return route
    },
    truncatedTitle () {
      let title = this.deposit.name
      if (title.length > 35) {
        title = title.slice(0, 32) + '...'
      }
      return title
    }
  },
  async created () {
    this.loading = true
    if (!this.depositSource) {
      try {
        await this.load(this.depositId)
      } catch (e) {
        this.handleError(e)
      }
    }

    if (!this.editable(this.depositId)) {
      await this.$router.replace({
        name: 'deposit-details',
        params: {
          projectId: this.projectId,
          depositId: this.depositId
        }
      })
    }

    this.deposit = cloneDeep(this.depositSource)
    // Quick fix — remove rightsholder to prevent error on save
    if (this.deposit.actors?.length) {
      this.deposit.actors = this.deposit.actors.filter(a => a.rights === 'author')
    }
    this.loading = false
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
      setError: 'error/set',
    }),
    ...mapActions({
      loadPrv: 'deposits/dpLoadOne',
      loadBiz: 'depositsBusiness/dpLoadOne',
      updatePrv: 'deposits/dpUpdate',
      updateBiz: 'depositsBusiness/dpUpdate',
      updateWithFilesPrv: 'deposits/dpUpdateWithFiles',
      updateWithFilesBiz: 'depositsBusiness/dpUpdateWithFiles',
      removePrv: 'deposits/dpRemove',
      removeBiz: 'depositsBusiness/dpRemove',
      clearDepositsPrv: 'deposits/dpClear',
      clearDepositsBiz: 'depositsBusiness/dpClear',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    load () {
      return (this.isBusinessAccount ? this.loadBiz : this.loadPrv)(...arguments)
    },
    update () {
      return (this.isBusinessAccount ? this.updateBiz : this.updatePrv)(...arguments)
    },
    updateWithFiles () {
      return (this.isBusinessAccount ? this.updateWithFilesBiz : this.updateWithFilesPrv)(...arguments)
    },
    remove () {
      return (this.isBusinessAccount ? this.removeBiz : this.removePrv)(...arguments)
    },
    clearDeposits () {
      return (this.isBusinessAccount ? this.clearDepositsBiz : this.clearDepositsPrv)(...arguments)
    },

    input (value) {
      this.deposit = value
    },
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async submit () {
      if (!this.$refs.form.validate()) return

      this.saving = true
      this.$refs.form.resetValidation()
      this.clearErrors()

      let deposit = cloneDeep(this.deposit)
      try {
        if (this.contentType === 'file') {
          await this.updateWithFiles(deposit)
        } else {
          await this.update(deposit)
        }
        this.showNotify({
          text: this.$t('DepositUpdated', {title: this.truncatedTitle}),
          color: 'success'
        })
        await this.$router.push(this.backRoute)
      } catch (e) {
        if (!this.handleError(e)) {
          if (e.response?.status === 422) {
            this.serverFeedback = {...e.response.data.message}
          }
        }
      } finally {
        this.saving = false
      }
    },
    async trash () {
      this.removing = true
      try {
        await this.remove(this.deposit.id)
        await this.clearDeposits()
        this.showNotify({
          text: this.$t('DepositDraftDeleted', {title: this.truncatedTitle}),
          color: 'warning',
          timeout: 15000
        })
        await this.$router.push(this.backRoute)
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.removing = false
      }
    }
  }
}
</script>
